@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
@import '~react-datepicker/dist/react-datepicker.css';

body{
  font-family: 'Kanit', sans-serif !important;
}

.ant-table-cell{
  font-size: 14px;
}

h1,h2,h3,h4,h5,h6,
.ant-col{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.mh-40px{
  min-height: 40px !important;
}
.ant-form-item-label > label{
  margin-bottom: 0 !important;
}

.right-pos .anticon-user{
  position: relative;
  top: -3px;
}

.menu-trigger span{
  position: relative;
  top: -20px;
  margin-right: 20px;
  color: #fff;
}
.ant-form-custom .ant-row{
  margin-bottom: 12px !important;
}

.ant-row.ant-form-item{
  margin: 0px;
  padding-left: 4px;
  padding-right: 4px;
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
  margin: 0 0 3px 0 !important;
}

.anticon{
  top: -2px;
  position: relative;
}

.cursor-grab{
  cursor: grab;
  &:active{
    cursor: grabbing;
  }
}

.ant-upload img{
  width: 100%;
}

.row-dragging{
  white-space: pre;
  vertical-align: middle;
  td{
    padding: 8px 8px;
    border: 1px solid #f0f0f0;
  }
}

.slip-uploader {
  background: url('/images/upload-slip.png') no-repeat center center #838383;
  background-size: 400px;
  border-radius: 10px;
  overflow: hidden;
}

.slip-uploader input {
  position: absolute;
  z-index: 100;
  background: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  border: 0 !important;
  z-index: 2;
  display: block !important;
  width: 100%;
}

.remove-slip {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 30px;
  padding: 4px;
  font-weight: bold;
  right: 20px;
  color: white;
  margin-top: 5px;
  z-index: 1000;
  cursor: pointer;
  display: none;
}

.remove-slip.show {
  display: block;
}
// .anticon-minus-circle{
//   margin-top: 31px;
//   margin-left: 2px;
//   margin-right: 6px;
//   font-size: 21px;
//   color: red !important;
// }

.possibleDeposit {
  padding: 10px 0px;
  background: #fdf6f6;
  border-left: 6px #6c6276 solid;
}

.accountRow {
  padding: 10px 0px;
  background: #fdf6f6;
  border-left: 6px #6ae29a solid;
}

#summary-history .col-xl-6 .ant-alert-message {
  font-size: 16px!important;
}


@media only screen and (min-width: 1200px) {
  #summary-history .mBorderLeft {
    border-left: 1px solid black;
  }
}


.ant-layout-header{
  background: #222222;
  border-bottom:1px solid #eee;
}
.menu-trigger span{
  color:#fff;
}
.ant-layout-sider{
  border-right:1px solid #eee;
}
.ant-layout-sider{ 
  background: #fff;
}
.ant-layout-content{
  padding:0 15px;
}
#header-profile{
  border-radius: 5em;
}
.menu-logo{
  cursor: pointer;
}
.ant-layout-footer{
  padding: 25px 50px 50px;
}

.dashboard-item{
  background: #fff;
  padding: 30px 20px !important;
  box-shadow: 0px 0px 8px #eee;
  margin-bottom: 10px;
  .icon{
    font-size: 23px;
    display: block;
    width: 50px;
    height: 50px;
    background: #193038;
    padding: 8px;
    border-radius: 10px;
    color: #fff;
  }
  .value{
    text-align: left;
    font-weight: bold;
    font-size:22px;
    color:#555
  }
  .label{
    text-align: left;
    color:#888;
    font-size:13px
  }
  
}
.regiter-count{
  .icon{
    color:#00cfe8;
    background: rgba(0,207,232,.12);
  }
}
.regiter-dep-count{
  .icon{
    color:#284ec7;
    background: rgb(40 143 199 / 12%);
  }
}
.user-active-count{
  .icon{
    color:#7367f0;
    background: rgba(115,103,240,.12);
  }
}
.dep-count{
  .icon{
    color:#28c76f;
    background: rgba(40,199,111,.12);
  }
}
.with-count{
  .icon{
    color:#ea5455;
    background: rgba(234,84,85,.12);
  }
}
.diff-count{
  .icon{
    color:#ff9f43;
    background: rgba(255,159,67,.12);
  }
}
.turnover-count{
  .icon{
    color:#ff43fd;
    background: rgb(231 67 255 / 12%);
  }
}
.winloss-count{
  .icon{
    color:#28c76f;
    background: rgba(40,199,111,.12);
  }
}
.login-logo{
  width: 180px;
  height: 180px;
  border-radius: 100em;
  background:#575757;
  padding:50px 20px 0;
  margin:20px auto 30px;
  .logo-label{
    text-align: center;
    color:#fff;
    font-size: 11px;
  }
}

@media only screen and (max-width: 600px) {
  .ant-layout-sider{
    position: fixed;
    z-index: 90;
    flex: 0 0 50px !important;
    min-width: 50px !important;
    top:63px
  }
  .ant-menu-submenu-popup{
    position: fixed;
  }
  .ant-breadcrumb{
    display: none;
  }
  .main-content{
    padding: 65px 0px 0 50px !important;
    h1{
      display: none;
    }
    h4{
      font-size:20px;
      padding-bottom: 10px !important;
    }
  }
  .ant-layout-content{
    padding: 0;
  }
  
  .ant-layout-header{
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
}